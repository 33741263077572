<script setup>
import { useI18n } from 'vue-i18n';
import { useHead } from "@unhead/vue";

const { t } = useI18n();

useHead( {
  title: t('associations.meta.title'),
  meta: [
    { name: 'description', content: t('associations.meta.description') }
  ]
})
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('associations.hero.title') }} <span>{{ t('associations.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="intro">
        <div class="container">
          <div class="content">
            <div class="-text">
              <h2>{{ t('associations.intro.title') }}</h2>
              <p>{{ t('associations.intro.description1') }}</p>
              <p>{{ t('associations.intro.description2') }}</p>
              <p>{{ t('associations.intro.description3') }}</p>
            </div>
            <div class="-image" style="background-image: url('../assets/img/public/associacoes-doencas-raras.jpg')" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000"></div>
          </div>
        </div>
      </section>

      <section class="benefits">
        <div class="container">
          <div class="intro">
            <h2>{{ t('associations.benefits.title') }}</h2>
            <h3>{{ t('associations.benefits.subtitle') }}</h3>
          </div>
          <div class="items">
            <div class="-item" v-for="(benefit, index) in benefits" :key="index" :data-aos="'fade-in'" :data-aos-delay="(index + 1) * 50" data-aos-duration="1500">
              <span>{{ index + 1 }}</span>
              <h4>{{ t(benefit.title) }}</h4>
              <p>{{ t(benefit.description) }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="operation">
        <div class="container">
          <div class="intro">
            <h2>{{ t('associations.operation.title') }}</h2>
            <h3>{{ t('associations.operation.subtitle') }}</h3>
          </div>
          <div class="items">
            <div class="-item" v-for="(step, index) in operationSteps" :key="index" :data-aos="'fade-right'" :data-aos-delay="(index + 1) * 100" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div class="-img" :style="{ backgroundImage: `url(${step.image})` }"></div>
              <span>{{ index + 1 }}</span>
              <div class="-text">
                <h4>{{ t(step.title) }}</h4>
                <p>{{ t(step.description) }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="suport">
        <div class="container">
          <div class="content">
            <div class="-intro">
              <h2>{{ t('associations.suport.title') }}</h2>
              <p>{{ t('associations.suport.description1') }}</p>
              <p><strong>{{ t('associations.suport.description2') }}</strong></p>
              <div class="-contact">
                <ul>
                  <li><a href="https://api.whatsapp.com/send/?phone=5511998680834" target="_blank">{{ t('associations.suport.contact.whatsapp') }}</a></li>
                  <li><a href="mailto:atendimento@mypharma2go.com">atendimento@mypharma2go.com</a></li>
                </ul>
              </div>
            </div>
            <div class="-faq">
              <h3>{{ t('associations.faq.title') }}</h3>
              <div class="accordion">
                <div class="accordion__item" tabindex="1" v-for="(faq, index) in faqs" :key="index">
                  <div class="accordion__title">{{ t(faq.question) }}</div>
                  <div class="accordion__content">
                    <p>{{ t(faq.answer) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta">
        <div class="container">
          <p>{{ t('associations.cta.description') }}</p>
          <a href="contato" class="button">{{ t('associations.cta.button') }}</a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AppAssociations",
  data() {
    return {
      bodyClass: 'public-child -associacoes',
      benefits: [
        { title: 'associations.benefits.item1.title', description: 'associations.benefits.item1.description' },
        { title: 'associations.benefits.item2.title', description: 'associations.benefits.item2.description' },
        { title: 'associations.benefits.item3.title', description: 'associations.benefits.item3.description' },
        { title: 'associations.benefits.item4.title', description: 'associations.benefits.item4.description' },
        { title: 'associations.benefits.item5.title', description: 'associations.benefits.item5.description' },
        { title: 'associations.benefits.item6.title', description: 'associations.benefits.item6.description' },
        { title: 'associations.benefits.item7.title', description: 'associations.benefits.item7.description' },
        { title: 'associations.benefits.item8.title', description: 'associations.benefits.item8.description' },
        { title: 'associations.benefits.item9.title', description: 'associations.benefits.item9.description' },
        { title: 'associations.benefits.item10.title', description: 'associations.benefits.item10.description' }
      ],
      operationSteps: [
        { image: '../assets/img/public/associacoes-doencas-raras-identificacao-das-necessidades.jpg', title: 'associations.operation.item1.title', description: 'associations.operation.item1.description' },
        { image: '../assets/img/public/associacoes-doencas-raras-consolidacao-de-pedidos.jpg', title: 'associations.operation.item2.title', description: 'associations.operation.item2.description' },
        { image: '../assets/img/public/associacoes-doencas-raras-planejamento-logistico.jpg', title: 'associations.operation.item3.title', description: 'associations.operation.item3.description' },
        { image: '../assets/img/public/associacoes-doencas-raras-compliance-e-aprovacao.jpg', title: 'associations.operation.item4.title', description: 'associations.operation.item4.description' },
        { image: '../assets/img/public/associacoes-doencas-raras-entrega-final.jpg', title: 'associations.operation.item5.title', description: 'associations.operation.item5.description' }
      ],
      faqs: [
        { question: 'associations.faq.question1', answer: 'associations.faq.answer1' },
        { question: 'associations.faq.question2', answer: 'associations.faq.answer2' },
        { question: 'associations.faq.question3', answer: 'associations.faq.answer3' }
      ]
    }
  },
  mounted() {
    document.body.classList.add(...this.bodyClass.split(' '))
  },
  beforeUnmount() {
    document.body.classList.remove(...this.bodyClass.split(' '))
  },
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
